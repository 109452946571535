import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';
import { AllPages } from './pages';
import { makeUrl } from '../utils/urlUtils';
import { ColorMode } from './page';

export interface TopNavParams {
  pageName: string;
  colorMode: ColorMode;
  toggleColorMode: () => void;
}

const TopNav = ({ pageName, colorMode, toggleColorMode }: TopNavParams): JSX.Element => {
  return (
    <AppBar position='absolute'>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          {Object.entries(AllPages)
            .filter(([, pgConfig]) => pgConfig.topNav)
            .map(([pgKey, pgConfig]) => (
              <Button color='inherit' key={pgKey} disabled={pgKey === pageName} href={makeUrl(pgKey)}>
                {pgConfig.name ?? '-'}
              </Button>
            ))}
        </Box>
        <Box sx={{ flexGrow: 0, ml: 1 }}>
          <IconButton color='inherit' onClick={toggleColorMode}>
            {colorMode === 'dark' ? <LightMode /> : <DarkMode />}
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
