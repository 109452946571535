import { Box, Button, Stack, Typography } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';

export const IndexPage = (): JSX.Element => {
  return (
    <>
      <Box sx={{ my: 4 }}>
        <Typography>
          This is a testing website for Smital Tech. Your testing link should include something after the &quot;/&quot;.
          Please try again.
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography>
          Contact:
          <Button href='https://www.linkedin.com/in/petrsmital/' variant='contained' sx={{ mx: 2, pl: 1, pr: 2 }}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <LinkedIn sx={{ fontSize: '2rem' }} />
              <span>Petr Smital</span>
            </Stack>
          </Button>
        </Typography>
      </Box>
    </>
  );
};
