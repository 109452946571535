import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ThemeProvider, createTheme, Theme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { makeUrl } from '../utils/urlUtils';
import TopNav from './topNav';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { AllPages, PageConfig } from './pages';
import CssBaseline from '@mui/material/CssBaseline';

export type ColorMode = 'light' | 'dark';

const preferredColorMode: ColorMode =
  typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';

const pageNames: string[] = Object.keys(AllPages);
const pageNamesThatDoNotRequirePageParam: string[] = pageNames.filter(
  (pageName: string) => !AllPages[pageName].needsParams,
);

const Page = (): JSX.Element => {
  const navigate = useNavigate();
  const { pageName: _pageName, pageParam } = useParams();
  const pageName = _pageName ?? 'index';

  const [colorMode, setColorMode] = useState<ColorMode>(preferredColorMode);
  const [loading, setLoading] = useState(true);

  const theme: Theme = useMemo(() => createTheme({ palette: { mode: colorMode } }), [colorMode]);

  const toggleColorMode = (): void => {
    setColorMode((previous: ColorMode) => (previous === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    const acceptablePageNames: string[] = pageParam ? pageNames : pageNamesThatDoNotRequirePageParam;
    if (pageName && !acceptablePageNames.includes(pageName)) {
      navigate(makeUrl(pageNamesThatDoNotRequirePageParam[0]), { replace: true });
      return;
    }
    setLoading(false);
  }, [pageName]);

  const page: PageConfig | undefined = pageName ? AllPages[pageName] : undefined;
  const PageClass = page?.pageClass;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        {pageName && page && PageClass && (
          <>
            {!loading && <TopNav pageName={pageName} colorMode={colorMode} toggleColorMode={toggleColorMode} />}
            <Box component='main' sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
              <Toolbar />
              <Typography variant='h2' align='center' mt={2}>
                {page.name}
                {pageParam && (
                  <Box ml={2} sx={{ display: 'inline', opacity: 0.5 }}>
                    ({pageParam})
                  </Box>
                )}
              </Typography>
              <Container maxWidth={page.wide ? 'xl' : 'lg'} sx={{ mt: 4, mb: 4 }}>
                {loading ? <h1 className='text-center'>Loading...</h1> : <PageClass pageParam={pageParam} />}
              </Container>
            </Box>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Page;
