import { Routes, Route } from 'react-router-dom';
import Page from './components/page';

export const App = (): JSX.Element => {
  return (
    <Routes>
      <Route index element={<Page />} />
      <Route path='/:pageName' element={<Page />} />
      <Route path='/:pageName/:envName' element={<Page />} />
      <Route path='/:pageName/:envName/:pageParam' element={<Page />} />
    </Routes>
  );
};
