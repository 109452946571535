import { IndexPage } from './index.page';
import { MinityBackupPage } from './minity-backup.page';

export interface PageParams {
  pageParam?: string;
}

export interface PageConfig {
  name?: string;
  pageClass: (params: PageParams) => JSX.Element;
  topNav: boolean;
  wide?: boolean;
  needsParams?: boolean;
}

export const AllPages: { [key: string]: PageConfig } = {
  index: {
    name: 'Smital Tech',
    pageClass: IndexPage as (params: PageParams) => JSX.Element,
    topNav: true,
    wide: false,
  },
  'minity-backup': { name: 'Minity Backup', pageClass: MinityBackupPage, topNav: false, wide: false },
};
