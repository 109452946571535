import { Box, Button, FormControl, Input, InputLabel, Link } from '@mui/material';
import { useState } from 'react';

export const MinityBackupPage = (): JSX.Element => {
  const [password, setPassword] = useState<string>('');
  const [linx, setLinx] = useState<string[] | null>(null);

  return (
    <>
      <Box sx={{ my: 4 }}>
        <FormControl>
          <InputLabel htmlFor='password'>Password</InputLabel>
          <Input
            id='password'
            type='text'
            aria-describedby='password'
            value={password}
            onChange={(e): void => {
              setPassword(e.currentTarget.value);
            }}
          />
        </FormControl>
        <Button
          variant='contained'
          sx={{ mx: 2, pl: 1, pr: 2 }}
          onClick={async (): Promise<void> => {
            if (!password) {
              // eslint-disable-next-line no-alert
              alert('Fill in password first');
              return;
            }
            try {
              const response: Response = await fetch(
                'https://wg7h4lygs6w4kbqvw2xwh7vr2i0jqlhr.lambda-url.eu-central-1.on.aws',
                {
                  method: 'POST',
                  cache: 'no-cache',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  redirect: 'follow',
                  body: JSON.stringify({ password }),
                },
              );
              const status: number = response.status;
              const text: string = await response.text();
              let newLinx: string[] = [];
              try {
                newLinx = JSON.parse(text) as string[];
              } catch {
                throw new Error(`HTTP ${status}: ${text}`);
              } finally {
                setLinx(newLinx);
              }
            } catch (e) {
              // eslint-disable-next-line no-alert
              alert(e);
            }
          }}
        >
          Get link(s)
        </Button>
      </Box>
      {linx?.map((link: string) => (
        <Box sx={{ my: 4 }} key={link}>
          <Link href={link} target='_blank'>
            {link.split('?')[0].split('/').slice(-1)}
          </Link>
        </Box>
      ))}
    </>
  );
};
